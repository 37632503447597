<template>
  <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.value)">
    <span>{{ translatePermission(params.value) }}</span>
  </vs-chip>
</template>

<script>
import i18n from "../../../../i18n/i18n";
export default {
  name: "CellRendererPermission",
  computed: {
    chipColor() {
      return value => {
        if (value === 2) return "danger";

        if (value === 1) return "success";

        if (value === 0) return "primary";
        else "primary";
      };
    }
  },
  methods: {
    translatePermission(value) {
      if (value === 2) return i18n.t("permission.root");

      if (value === 1) return i18n.t("permission.admin");

      if (value === 0) return i18n.t("permission.user");
    }
  }
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success), 0.15);
    color: rgba(var(--vs-success), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning), 0.15);
    color: rgba(var(--vs-warning), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger), 0.15);
    color: rgba(var(--vs-danger), 1) !important;
    font-weight: 500;
  }
}
</style>
