
<template lang="html">
	<div id="page-user-list">
		<div id="user-list-content" class="vx-card p-6 vs-con-loading__container">
			<vs-prompt :title="$t('actions.exportData')" class="export-options" @cancel="clearFields" @accept="exportToExcel" :accept-text="$t('actions.export')" :cancel-text="$t('actions.cancel')" @close="clearFields" :active.sync="activePrompt">
					<vs-input v-model="fileName" :placeholder="$t('actions.enterFilename')" class="w-full" />
					<v-select v-model="selectedFormat" :clearable="false" :options="formats" class="my-4" />
			</vs-prompt>

			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-switch color="success" v-model="status">
					<span slot="on">{{$t('status.active')}}</span>
					<span slot="off">{{$t('status.inactive')}}</span>
				</vs-switch>
			</vs-row>

			<div class="flex flex-wrap justify-between items-center">
				<div v-if="$store.state.AppActiveUser.type > 0" 
          class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addItem">
					<feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
					<span class="ml-2 text-base text-primary">{{ $t('user.actions.new') }}</span>
				</div>

				<div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
					<vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" :label-placeholder="$t('navbar.search')" />
					<vs-button class="mb-4 md:mb-0 mt-5" @click="activePrompt=true">{{ $t('actions.export') }}</vs-button>
				</div>
			</div>

			<ag-grid-vue
					ref="agGridTable"
					:gridOptions="gridOptions"
					class="ag-theme-material w-100 my-4 ag-grid-table"
					:columnDefs="columnDefs"
					:defaultColDef="defaultColDef"
					:rowData="data"
					rowSelection="multiple"
					colResizeDefault="shift"
					:animateRows="true"
					:pagination="true"
					:paginationPageSize="paginationPageSize"
					:suppressPaginationPanel="true"
					:enableRtl="$vs.rtl">
			</ag-grid-vue>
			<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
		</div>
	</div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererPermission from "./cell-renderer/CellRendererPermission.vue";

export default {
  components: {
    AgGridVue,
    vSelect,
    CellRendererActions,
    CellRendererPermission,
  },
  data: () => ({
    data: null,
    dataRaw: null,
    status: true,
    searchQuery: "",

    //Export2Excel
    activePrompt: false,
    fileName: "",
    formats: ["xlsx", "csv", "txt"],
    cellAutoWidth: true,
    selectedFormat: "xlsx",
    headerTitle: ["Id", "Email", i18n.t("common.name")],
    headerVal: ["id", "login", "name"],

    // AgGrid
    gridOptions: {
      localeTextFunc: function (key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true,
    },
    maxPageNumbers: 7,
    gridApi: null,
    defaultColDef: {
      sortable: true,
      //editable: true,
      //resizable: true,
      suppressMenu: true,
      floatingFilter: true,
    },
    columnDefs: [
      {
        headerName: i18n.t("common.id"),
        field: "id",
        width: 150,
        suppressSizeToFit: true,
        filter: true,
      },
      {
        headerName: i18n.t("common.name"),
        field: "name",
        sort: "asc",
        width: 250,
        filter: true,
      },
      {
        headerName: "Email",
        field: "login",
        width: 250,
        filter: true,
      },
      {
        headerName: i18n.t("user.permission"),
        field: "type",
        width: 150,
        suppressSizeToFit: true,
        cellRendererFramework: "CellRendererPermission",
      },
      {
        headerName: i18n.t("actions.title"),
        width: 100,
        suppressSizeToFit: true,
        field: "actions",
        cellRendererFramework: "CellRendererActions",
      },
    ],
  }),
  watch: {
    status: function (val) {
      this.data = this.dataRaw.filter((x) => x.active == val);
    },
    "$store.state.windowWidth"(val) {
      this.resizeTable(val);
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    resizeTable(width) {
      if (width <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("actions", null);
      } else {
        this.gridOptions.columnApi.setColumnPinned("actions", "right");
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    addItem() {
      this.$router.push({ name: "users-new", params: { user: {}, new: true } });
    },
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#user-list-content",
        scale: 0.5,
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "user/all" })
        .then((data) => {
          self.$vs.loading.close("#user-list-content > .con-vs-loading");
          self.data = data.filter((x) => x.active == this.status);
          self.dataRaw = data;
        })
        .catch((error) => {
          self.$vs.loading.close("#user-list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger",
          });
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        //const list = this.data

        //get only filtered data
        const list = [];
        this.gridOptions.api
          .getModel()
          .rootNode.childrenAfterFilter.forEach((x) => {
            list.push(x.data);
          });

        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    clearFields() {
      (this.fileName = ""), (this.selectedFormat = "xlsx");
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loadData();
    this.resizeTable(this.$store.state.windowWidth);
  },
};
</script>